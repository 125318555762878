import { FirebaseUser, UserAccount, UserAccountProps } from "@/types/types";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { getFirebase } from "./getFirebase";

export default async function getUserAccount(user: FirebaseUser) {
  const { db } = getFirebase();

  const docRef = doc(db, "users", user.uid);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    const newUserAccount = await createUserAccount(user);
    return newUserAccount;
  }

  const userAccount: UserAccount = {
    id: docSnap.id,
    email: docSnap.data()?.email,
    createdAt: docSnap.data()?.createdAt,
    updatedAt: docSnap.data()?.updatedAt,
    deletedAt: docSnap.data()?.deletedAt,
  };

  return userAccount;
}

export async function getUserAccountFromId(id: string) {
  const { db } = getFirebase();

  const docRef = doc(db, "users", id);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    return null;
  }

  const userAccount: UserAccount = {
    id: docSnap.id,
    email: docSnap.data()?.email,
    createdAt: docSnap.data()?.createdAt,
    updatedAt: docSnap.data()?.updatedAt,
    deletedAt: docSnap.data()?.deletedAt,
  };

  return userAccount;
}

export async function getUserAccountFromEmail(email: string) {
  const { db } = getFirebase();

  // Check if user exists, if not send invite
  const usersPath = "users";
  const userQuery = query(
    collection(db, usersPath),
    where("email", "==", email),
    where("deletedAt", "==", null)
  );
  const usersQuerySnapshot = await getDocs(userQuery);

  // Find user with email
  const userDocSnap = usersQuerySnapshot.docs.find((doc) => {
    return doc.data().email === email;
  });

  if (!userDocSnap) {
    console.warn("User does not exist, send invite");
    return;
  }

  const user: UserAccount = {
    id: userDocSnap.id,
    email: userDocSnap.data().email,
    createdAt: userDocSnap.data().createdAt,
    updatedAt: userDocSnap.data().updatedAt,
    deletedAt: userDocSnap.data().deletedAt,
  };

  return user;
}

export async function createUserAccount(
  user: FirebaseUser
): Promise<UserAccount> {
  const { db } = getFirebase();

  const now = Timestamp.now();

  const userAccount: UserAccountProps = {
    email: user.email ?? undefined,
    createdAt: now,
    updatedAt: now,
    deletedAt: null,
  };

  const usersRef = collection(db, "users");
  await setDoc(doc(usersRef, user.uid), userAccount);

  const userAccountRef = doc(db, "users", user.uid);
  const docSnap = await getDoc(userAccountRef);

  const newUserAccount: UserAccount = {
    id: docSnap.id,
    email: docSnap.data()?.email,
    createdAt: docSnap.data()?.createdAt,
    updatedAt: docSnap.data()?.updatedAt,
    deletedAt: docSnap.data()?.deletedAt,
  };

  return newUserAccount;
}
