import { getFirebase } from "@/lib/getFirebase";
import { Transaction, TransactionProps, UserAccount } from "@/types/types";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { updateAccountBalance } from "./account";
import {
  getAccountsPath,
  getTransactionPath,
  getTransactionsPath,
} from "./paths";

export async function addTransaction({
  userId,
  accountId,
  name,
  amount,
  type,
  date,
  description,
}: {
  userId: string;
  accountId: string;
  name: string;
  amount: number;
  type: "income" | "expense";
  date: string;
  description?: string;
}) {
  const { db } = getFirebase();

  const now = Timestamp.now();

  const transaction: TransactionProps = {
    name: name,
    amount: Math.abs(amount),
    type: type,
    date: date,
    description: description,
    createdAt: now,
    updatedAt: now,
    deletedAt: null,
  };

  const path = getTransactionsPath(userId, accountId);

  try {
    await addDoc(collection(db, path), transaction);
    updateAccountBalance(userId, accountId);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export async function updateTransaction({
  userId,
  accountId,
  transactionId,
  transaction,
}: {
  userId: string;
  accountId: string;
  transactionId: string;
  transaction: TransactionProps;
}) {
  const { db } = getFirebase();

  const path = getTransactionPath(userId, accountId, transactionId);
  const docRef = doc(db, path);

  try {
    await updateDoc(docRef, {
      ...transaction,
      updatedAt: serverTimestamp(),
    });
    updateAccountBalance(userId, accountId);
  } catch (e) {
    console.error("Error updating document: ", e);
  }
}

export async function deleteTransaction({
  userId,
  accountId,
  transactionId,
}: {
  userId: string;
  accountId: string;
  transactionId: string;
}) {
  const { db } = getFirebase();

  const path = getTransactionPath(userId, accountId, transactionId);
  const docRef = doc(db, path);

  await updateDoc(docRef, {
    deletedAt: serverTimestamp(),
  });

  updateAccountBalance(userId, accountId);
}

export async function restoreTransaction({
  userId,
  accountId,
  transactionId,
}: {
  userId: string;
  accountId: string;
  transactionId: string;
}) {
  const { db } = getFirebase();

  const path = getTransactionPath(userId, accountId, transactionId);
  const docRef = doc(db, path);

  await updateDoc(docRef, {
    deletedAt: null,
  });

  updateAccountBalance(userId, accountId);
}

export async function queryTransactions({
  userId,
  accountId,
}: {
  userId: string;
  accountId: string;
}) {
  const { db } = getFirebase();
  const path = getTransactionsPath(userId, accountId);
  const q = query(collection(db, path), where("deletedAt", "==", null));
  const transactions: Transaction[] = [];
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    transactions.push({
      id: doc.id,
      ...doc.data(),
    } as Transaction);
  });

  return transactions;
}

// Admin only function to fix negative expenses
export async function fixNegativeExpenses() {
  const { db } = getFirebase();
  const usersPath = "users";
  const usersQuery = query(collection(db, usersPath));
  const usersQuerySnapshot = await getDocs(usersQuery);
  const users: UserAccount[] = [];
  usersQuerySnapshot.forEach((doc) => {
    users.push({
      id: doc.id,
      ...doc.data(),
    } as UserAccount);
  });

  users.forEach(async (user: UserAccount) => {
    const accountsPath = getAccountsPath(user.id);
    const accountsQuery = query(collection(db, accountsPath));
    const accountsQuerySnapshot = await getDocs(accountsQuery);
    const accounts: UserAccount[] = [];
    accountsQuerySnapshot.forEach((doc) => {
      accounts.push({
        id: doc.id,
        ...doc.data(),
      } as UserAccount);
    });

    accounts.forEach(async (account: UserAccount) => {
      const transactionsPath = `users/${user.id}/accounts/${account.id}/transactions`;
      const transactionsQuery = query(
        collection(db, transactionsPath),
        where("type", "==", "expense"),
        where("amount", "<", 0)
      );
      const transactionsQuerySnapshot = await getDocs(transactionsQuery);
      const transactions: Transaction[] = [];
      transactionsQuerySnapshot.forEach((doc) => {
        transactions.push({
          id: doc.id,
          ...doc.data(),
        } as Transaction);
      });

      transactions.forEach(async (transaction: Transaction) => {
        const transactionPath = `users/${user.id}/accounts/${account.id}/transactions/${transaction.id}`;
        const transactionDocRef = doc(db, transactionPath);
        await updateDoc(transactionDocRef, {
          amount: Math.abs(transaction.amount),
        });
      });
    });
  });
}
