import { getFirebase } from "@/lib/getFirebase";
import {
  Account,
  SharedAccountProps,
  SharedUser,
  SharedUserProps,
  UserAccount,
} from "@/types/types";
import {
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { getAccount } from "./account";
import {
  getAccountPath,
  getAccountSharedUsersPath,
  getSharedAccountPath,
  getSharedAccountsPath,
} from "./paths";
import { getUserAccountFromEmail } from "./userAccount";

export async function shareAccountWithUser({
  userId,
  accountId,
  email,
}: {
  userId: string;
  accountId: string;
  email: string;
}) {
  const userToShareTo = await getUserAccountFromEmail(email);
  if (!userToShareTo) return;

  const account = await getAccount(userId, accountId);
  if (!account) return;
  if (!account.sharedAt) {
    await setAccountAsShared({ userId, accountId });
  }

  const sharedUsers = await getAccountSharedUsers({ userId, accountId });

  if (!sharedUsers.find((sharedUser) => sharedUser.id === userToShareTo.id)) {
    await addUserToSharedUsers({
      userId,
      accountId,
      email,
      user: userToShareTo,
    });
  }

  // Check if shared user has this account in their sharedAccounts collection, if not, add it
  const sharedAccountsOfUser = await getSharedAccountsOfUser({
    userId: userToShareTo.id,
  });

  // If shared user does not have this account in their sharedAccounts collection, add it
  if (
    !sharedAccountsOfUser.find(
      (sharedAccount) => sharedAccount.id === accountId
    )
  ) {
    await addAccountToUsersSharedAccounts({
      userId: userToShareTo.id,
      account,
      ownerId: userId,
    });
  }
}

async function setAccountAsShared({
  userId,
  accountId,
}: {
  userId: string;
  accountId: string;
}) {
  const { db } = getFirebase();

  const path = getAccountPath(userId, accountId);
  const docRef = doc(db, path);

  await updateDoc(docRef, {
    sharedAt: serverTimestamp(),
  });
}

export async function getAccountSharedUsers({
  userId,
  accountId,
}: {
  userId: string;
  accountId: string;
}): Promise<SharedUser[]> {
  const { db } = getFirebase();

  const sharedUsersPath = getAccountSharedUsersPath(userId, accountId);
  const sharedUsersQuery = query(
    collection(db, sharedUsersPath),
    where("deletedAt", "==", null)
  );
  const sharedUsersQuerySnapshot = await getDocs(sharedUsersQuery);

  const sharedUsers: SharedUser[] = sharedUsersQuerySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      email: doc.data().email,
      createdAt: doc.data().createdAt,
      updatedAt: doc.data().updatedAt,
      deletedAt: doc.data().deletedAt,
    };
  });

  return sharedUsers;
}

export async function updateBalanceOfSharedAccount({
  sharedUserId,
  accountId,
  balance,
}: {
  sharedUserId: string;
  accountId: string;
  balance: number;
}) {
  const { db } = getFirebase();

  const sharedAccountPath = getSharedAccountPath(sharedUserId, accountId);
  const docRef = doc(db, sharedAccountPath);

  await updateDoc(docRef, {
    balance: balance,
  });
}

async function addUserToSharedUsers({
  userId,
  accountId,
  email,
  user,
}: {
  userId: string;
  accountId: string;
  email: string;
  user: UserAccount;
}) {
  const { db } = getFirebase();

  const now = Timestamp.now();

  const id = user.id;

  const newSharedUser: SharedUserProps = {
    email: email,
    createdAt: now,
    updatedAt: now,
    deletedAt: null,
  };

  const sharedUsersPath = getAccountSharedUsersPath(userId, accountId);
  const sharedUsersRef = collection(db, sharedUsersPath);
  await setDoc(doc(sharedUsersRef, id), newSharedUser);
}

async function getSharedAccountsOfUser({ userId }: { userId: string }) {
  const { db } = getFirebase();

  const sharedAccountsPath = getSharedAccountsPath(userId);
  const sharedAccountsQuery = query(
    collection(db, sharedAccountsPath),
    where("deletedAt", "==", null)
  );
  const sharedAccountsQuerySnapshot = await getDocs(sharedAccountsQuery);

  const sharedAccounts = sharedAccountsQuerySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      userId: doc.data().userId,
      name: doc.data().name,
      balance: doc.data().balance,
      createdAt: doc.data().createdAt,
      updatedAt: doc.data().updatedAt,
      deletedAt: doc.data().deletedAt,
    };
  });

  return sharedAccounts;
}

async function addAccountToUsersSharedAccounts({
  userId,
  ownerId,
  account,
}: {
  userId: string;
  ownerId: string;
  account: Account;
}) {
  const { db } = getFirebase();

  const now = Timestamp.now();

  const sharedAccount: SharedAccountProps = {
    userId: ownerId,
    name: account.name,
    balance: account.balance,
    createdAt: now,
    updatedAt: now,
    deletedAt: null,
  };

  const sharedAccountsPath = getSharedAccountsPath(userId);
  const sharedAccountsRef = collection(db, sharedAccountsPath);
  await setDoc(doc(sharedAccountsRef, account.id), sharedAccount);
}

export async function unshareAccountWithUser({
  userId,
  accountId,
  email,
}: {
  userId: string;
  accountId: string;
  email: string;
}) {
  const userToUnshare = await getUserAccountFromEmail(email);
  if (!userToUnshare) return;

  const account = await getAccount(userId, accountId);
  if (!account) return;

  const sharedUsers = await getAccountSharedUsers({ userId, accountId });

  if (sharedUsers.find((sharedUser) => sharedUser.id === userToUnshare.id)) {
    await removeUserFromSharedUsers({
      userId,
      accountId,
      email,
      user: userToUnshare,
    });
  }

  // Check if shared user has this account in their sharedAccounts collection, if so, remove it
  const sharedAccountsOfUser = await getSharedAccountsOfUser({
    userId: userToUnshare.id,
  });

  // If shared user has this account in their sharedAccounts collection, remove it
  if (
    sharedAccountsOfUser.find((sharedAccount) => sharedAccount.id === accountId)
  ) {
    await removeAccountFromUsersSharedAccounts({
      userId: userToUnshare.id,
      account,
    });
  }
}

async function removeUserFromSharedUsers({
  userId,
  accountId,
  email,
  user,
}: {
  userId: string;
  accountId: string;
  email: string;
  user: UserAccount;
}) {
  const { db } = getFirebase();

  const now = Timestamp.now();

  const id = user.id;

  const sharedUser: SharedUserProps = {
    email: email,
    createdAt: now,
    updatedAt: now,
    deletedAt: now,
  };

  const sharedUsersPath = getAccountSharedUsersPath(userId, accountId);
  const sharedUsersRef = collection(db, sharedUsersPath);
  await setDoc(doc(sharedUsersRef, id), sharedUser);
}

async function removeAccountFromUsersSharedAccounts({
  userId,
  account,
}: {
  userId: string;
  account: Account;
}) {
  const { db } = getFirebase();

  const now = Timestamp.now();

  const sharedAccount: SharedAccountProps = {
    userId: userId,
    name: account.name,
    balance: account.balance,
    createdAt: now,
    updatedAt: now,
    deletedAt: now,
  };

  const sharedAccountsPath = getSharedAccountsPath(userId);
  const sharedAccountsRef = collection(db, sharedAccountsPath);
  await setDoc(doc(sharedAccountsRef, account.id), sharedAccount);
}
