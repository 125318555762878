"use client";

import { useEffect, useState } from "react";

export default function ButtonDarkMode() {
  const [darkMode, setDarkMode] = useState(false);

  const setDarkModeClass = (isDarkMode: boolean) => {
    setDarkMode(isDarkMode);
    const html = document.querySelector("html");
    if (!html) return;
    if (isDarkMode) {
      html.classList.add("dark");
    } else {
      html.classList.remove("dark");
    }
  };

  const toggleDarkModeAndSaveLocally = () => {
    setDarkModeClass(!darkMode);
    localStorage.setItem("theme", darkMode ? "light" : "dark");
  };

  const checkLocalStorage = () => {
    return localStorage.getItem("theme") ? localStorage.getItem("theme") : null;
  };

  const checkSystemTheme = () => {
    if (window.matchMedia) {
      return window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
    return false;
  };

  useEffect(() => {
    const localTheme = checkLocalStorage();

    if (localTheme) {
      if (localTheme === "dark") {
        setDarkModeClass(true);
      } else {
        setDarkModeClass(false);
      }
      return;
    }

    if (checkSystemTheme()) {
      setDarkModeClass(true);
      return;
    }
  }, []);

  return (
    <button
      className="rounded bg-gray-200 px-4 py-2 text-sm font-semibold text-white transition hover:scale-105 active:scale-95 dark:bg-gray-600 dark:text-black"
      onClick={() => toggleDarkModeAndSaveLocally()}>
      {darkMode ? "☀️" : "🌒"}
    </button>
  );
}
