import { getFirebase } from "@/lib/getFirebase";
import { Account, AccountProps } from "@/types/types";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { getAccountPath, getAccountsPath } from "./paths";
import {
  getAccountSharedUsers,
  updateBalanceOfSharedAccount,
} from "./sharedAccount";
import { queryTransactions } from "./transactions";

export async function addAccount({
  userAccountId,
  name,
}: {
  userAccountId: string;
  name: string;
}) {
  const { db } = getFirebase();

  const now = Timestamp.now();

  const account: AccountProps = {
    name: name,
    balance: 0,
    createdAt: now,
    updatedAt: now,
    deletedAt: null,
  };

  const path = getAccountsPath(userAccountId);

  try {
    await addDoc(collection(db, path), account);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export async function getAccount(userId: string, accountId: string) {
  const { db } = getFirebase();

  const accountPath = getAccountPath(userId, accountId);
  const accountDocRef = doc(db, accountPath);
  const accountDocSnap = await getDoc(accountDocRef);

  if (!accountDocSnap.exists()) {
    return;
  }

  const account: Account = {
    id: accountId,
    name: accountDocSnap.data().name,
    balance: accountDocSnap.data().balance,
    createdAt: accountDocSnap.data().createdAt,
    updatedAt: accountDocSnap.data().updatedAt,
    deletedAt: accountDocSnap.data().deletedAt,
    sharedAt: accountDocSnap.data().sharedAt,
  };

  return account;
}

export async function updateAccount({
  userId,
  accountId,
  name,
}: {
  userId: string;
  accountId: string;
  name: string;
}) {
  const { db } = getFirebase();

  const path = getAccountPath(userId, accountId);
  const docRef = doc(db, path);

  await updateDoc(docRef, {
    name: name,
    updatedAt: serverTimestamp(),
  });
}

export async function archiveAccount(userId: string, accountId: string) {
  const { db } = getFirebase();

  const path = getAccountPath(userId, accountId);
  const docRef = doc(db, path);

  await updateDoc(docRef, {
    archivedAt: serverTimestamp(),
  });
}

export async function unarchiveAccount(userId: string, accountId: string) {
  const { db } = getFirebase();

  const path = getAccountPath(userId, accountId);
  const docRef = doc(db, path);

  const result = await updateDoc(docRef, {
    archivedAt: null,
  });
}

export async function deleteAccount(userId: string, accountId: string) {
  const { db } = getFirebase();

  const path = getAccountPath(userId, accountId);
  const docRef = doc(db, path);

  await updateDoc(docRef, {
    deletedAt: serverTimestamp(),
  });
}

export async function restoreAccount(userId: string, accountId: string) {
  const { db } = getFirebase();

  const path = getAccountPath(userId, accountId);
  const docRef = doc(db, path);

  await updateDoc(docRef, {
    deletedAt: null,
  });
}

export async function updateAccountBalance(userId: string, accountId: string) {
  const transactions = await queryTransactions({ userId, accountId });

  const balance = transactions.reduce((acc, transaction) => {
    if (transaction.type === "income") {
      return acc + transaction.amount;
    } else {
      return acc - transaction.amount;
    }
  }, 0);

  const { db } = getFirebase();
  const path = getAccountPath(userId, accountId);
  const docRef = doc(db, path);

  await updateDoc(docRef, {
    balance: balance,
  });

  const sharedUsers = await getAccountSharedUsers({
    userId,
    accountId,
  });

  for (const sharedUser of sharedUsers) {
    updateBalanceOfSharedAccount({
      sharedUserId: sharedUser.id,
      accountId,
      balance,
    });
  }
}
