export function getUserAccountPath(userId: string): string {
  return `users/${userId}`;
}

export function getAccountsPath(userId: string): string {
  return `users/${userId}/accounts`;
}

export function getAccountPath(userId: string, accountId: string): string {
  return `users/${userId}/accounts/${accountId}`;
}

export function getAccountSharedUsersPath(
  userId: string,
  accountId: string
): string {
  return `users/${userId}/accounts/${accountId}/sharedUsers`;
}

export function getSharedAccountsPath(userId: string): string {
  return `users/${userId}/sharedAccounts`;
}

export function getSharedAccountPath(userId: string, accountId: string) {
  return `users/${userId}/sharedAccounts/${accountId}`;
}

export function getTransactionsPath(userId: string, accountId: string): string {
  return `users/${userId}/accounts/${accountId}/transactions`;
}

export function getTransactionPath(
  userId: string,
  accountId: string,
  transactionId: string
): string {
  return `users/${userId}/accounts/${accountId}/transactions/${transactionId}`;
}
