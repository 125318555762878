export default function Icon({
  code,
  className,
}: {
  code: string;
  className?: string;
}) {
  return (
    <span className={`material-symbols-outlined ${className}`}>{code}</span>
  );
}
