import Icon from "./Icon";

export function ButtonBasic(
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & {
    icon?: string;
    loading?: boolean;
    variant?: "primary" | "secondary" | "danger" | "text";
    onClick?: () => void;
  }
) {
  const {
    children,
    className,
    icon,
    loading,
    variant = "primary",
    onClick,
    ...rest
  } = props;

  const getBackgroundClass = () => {
    switch (variant) {
      case "primary":
        return `text-white bg-green-600 dark:bg-green-800 hover:bg-green-700 dark:hover:bg-green-900 active:bg-green-500 dark:active:bg-green-700`;
      case "secondary":
        return `text-neutral-800 dark:text-neutral-100 bg-gray-100 dark:bg-gray-600 hover:bg-gray-200 dark:hover:bg-gray-500 active:bg-gray-50 dark:active:bg-gray-400`;
      case "danger":
        return `text-white bg-red-600 dark:bg-red-800 hover:bg-red-700 dark:hover:bg-red-900 active:bg-red-500 dark:active:bg-red-700`;
      case "text":
        return `text-white hover:bg-gray-100 dark:hover:bg-gray-600 active:bg-gray-50 dark:active:bg-gray-400`;
      default:
        return `text-white bg-green-600 dark:bg-green-800 hover:bg-green-700 dark:hover:bg-green-900 active:bg-green-500 dark:active:bg-green-700`;
    }
  };

  const backgroundClass = getBackgroundClass();

  return (
    <button
      onClick={loading ? () => {} : onClick ? onClick : () => {}}
      className={`flex items-center justify-center gap-2 rounded px-4 py-2 text-center text-sm font-semibold transition hover:scale-105 active:scale-95 ${className} ${backgroundClass}`}
      {...rest}>
      {loading ? (
        <span>Loading...</span>
      ) : (
        <>
          {icon && <Icon code={icon} className="text-sm" />}
          <span>{children}</span>
        </>
      )}
    </button>
  );
}
